import React, { useState } from "react";
import {
  UserOutlined,
  DashboardOutlined,
  UnorderedListOutlined,
  QrcodeOutlined,
  FundProjectionScreenOutlined,
  FileOutlined,
  HistoryOutlined,
  FormOutlined,
  ApartmentOutlined,
  ClockCircleOutlined,
  DatabaseOutlined
} from "@ant-design/icons";
import { MdDashboard } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import Logo from "../../../assets/logoGreenbuilt.png";
import "./styles.css";

const { Sider } = Layout;

const Sidebar = ({ setTitle }) => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);

  const handleNavigate = (path, title) => {
    navigate(path);
    setTitle(title);
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className="custom-sidebar"
      style={{
        backgroundColor: "#fff",
        boxShadow: "1px 1px 8px #c1c1c1",
        zIndex: 2,
        position: "relative",
      }}
    >
      <div className="flex justify-center items-center p-4">
        <img
          src={Logo}
          alt="Logo"
          className={`duration-300 ${collapsed ? "w-0" : "w-44"}`}
        />
      </div>
      <Menu
        className="custom-selected font-montserrat"
        style={{ marginTop: "20px" }}
        theme=""
        mode="inline"
        defaultSelectedKeys={["1"]}
      >
        <Menu.Item
          key="1"
          icon={<DashboardOutlined />}
          onClick={() => handleNavigate("/admin/dashboard", "Statistics")}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          icon={<UserOutlined />}
          onClick={() => handleNavigate("/admin/users", "Users")}
        >
          Users
        </Menu.Item>
        <Menu.Item
          key="3"
          icon={<QrcodeOutlined />}
          onClick={() => handleNavigate("/admin/approvals", "Approvals")}
        >
          Approvals
        </Menu.Item>
        <Menu.Item
          key="4"
          icon={<FundProjectionScreenOutlined />}
          onClick={() => handleNavigate("/admin/pointsmanager", "Points Manager")}
        >
          Points Manager
        </Menu.Item>
        <Menu.Item
          key="5"
          icon={<FormOutlined />}
          onClick={() => handleNavigate("/admin/actualconsumptionmanager", "Actual Consumption Manager")}
        >
          Actual Consumption Manager
        </Menu.Item>
        <Menu.Item
          key="6"
          icon={<ApartmentOutlined />}
          onClick={() => handleNavigate("/admin/assetmaster", "Asset Manager")}
        >
          Asset Manager
        </Menu.Item>
        <Menu.Item
          key="7"
          icon={<FileOutlined />}
          onClick={() => handleNavigate("/admin/staticsmanager", "Statics Manager")}
        >
          Statics Manager
        </Menu.Item>
        <Menu.Item
          key="8"
          icon={<UnorderedListOutlined />}
          onClick={() => handleNavigate("/admin/productmaster", "Product Manager")}
        >
          Product Manager
        </Menu.Item>
        <Menu.Item
          key="9"
          icon={<DatabaseOutlined />}
          onClick={() => handleNavigate("/admin/userlog", "User Events")}
        >
          User Events
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;