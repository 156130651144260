import React, { useEffect, useReducer } from "react";
import ActionButtons from "./components/actionsButtons/Index";
import { DataTable } from "./components/table/Index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Tooltip, Modal } from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { innerTableActionBtnDesign } from "./components/styles/innerTableActions";
import axios from "../appConfig/httpHelper";
import { EditEntry } from "./components/ActualConsumptionManger/EditEntry";

export const ActualConsumptionManager = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("jwt"));

  // Declaring the States Required for the Working of the Component
  const [actions, setActions] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      drawer: false,
      editPlan: false,
      loading: false,
      pagination: 15,
      trash: false,
    }
  );

  const { drawer, loading, editPlan, pagination, trash } = actions;

  const [value, setValue] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    { pointsApproval: [], drawerValue: {}, editValue: {} }
  );

  const { pointsApproval, drawerValue, editValue } = value;

  // Dummy data for testing
  const dummyData = [
    {
      id: 1,
      user: { name: "Amit Gupta" },
      date: "2024-05-01",
      month: "May",
      year: "2024",
      totalConsumption: 1200,
      totalGreenConsumption: 300,
      ebBillLocation: "http://example.com/bill1.pdf",
      isApproved: false,
    },
    {
      id: 2,
      user: { name: "Sneha Verma" },
      date: "2024-05-02",
      month: "May",
      year: "2024",
      totalConsumption: 950,
      totalGreenConsumption: 150,
      ebBillLocation: "http://example.com/bill2.pdf",
      isApproved: false,
    },
    {
      id: 3,
      user: { name: "Rohit Sharma" },
      date: "2024-05-03",
      month: "May",
      year: "2024",
      totalConsumption: 1100,
      totalGreenConsumption: 200,
      ebBillLocation: "http://example.com/bill3.pdf",
      isApproved: false,
    },
    {
      id: 4,
      user: { name: "Priya Singh" },
      date: "2024-05-04",
      month: "May",
      year: "2024",
      totalConsumption: 1050,
      totalGreenConsumption: 250,
      ebBillLocation: "http://example.com/bill4.pdf",
      isApproved: false,
    },
    // Add more dummy data as needed
  ];

  // Functions Used for Different Data
  const requestsCaller = () => {
    setActions({ loading: true });
    axios
      .get("/power-consumption/get-all??limit=50&offset=0", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setValue({
          pointsApproval: res.data.data,
        });
      })
      .catch((err) => console.log(err))
      .finally(setActions({ loading: false }));
  };

  useEffect(() => requestsCaller(), []);

  const approvePoints = (record) => {
    axios
      .post(
        `/power-consumption/approve/${record.id}`,
        {
          points: record?.totalGreenConsumption,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const deletePointsRequest = (record) => {
    axios
      .delete(`/power-consumption/delete/${record.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  // Table Column
  const columns = [
    {
      key: "userName",
      title: "User Name",
      render: (data) => data?.user?.name,
    },
    {
      key: "day",
      title: "Day",
      render: (data) => data.date,
    },
    {
      key: "month",
      title: "Month",
      render: (data) => data.month,
    },
    {
      key: "year",
      title: "Year",
      render: (data) => data.year,
    },
    {
      key: "totalConsumption",
      title: "Total Consumption",
      render: (data) => data.totalConsumption,
    },
    {
      key: "totalGreenConsumption",
      title: "Total Green Consumption",
      render: (data) => data.totalGreenConsumption,
    },
    {
      key: "actions",
      title: "Actions",
      width: 300,
      render: (record) => <ColumnActions record={record} />,
    },
  ];

  const ColumnActions = (props) => {
    return (
      <div className="flex justify-around">
        <Button type="secondary">
          <a href={props.record.ebBillLocation} target="_blank" rel="noopener noreferrer">
            <DownloadOutlined title="Download" style={innerTableActionBtnDesign} />
          </a>
        </Button>

        <Tooltip title="Edit">
          <Button
            onClick={() => {
              setActions({ editPlan: true });
              setValue({ editValue: props?.record });
            }}
            type="secondary"
          >
            <EditOutlined title="Edit" style={innerTableActionBtnDesign} />
          </Button>
        </Tooltip>

        <Tooltip title="Approve">
          <Button onClick={() => approvePoints(props?.record)} type="secondary">
            <CheckOutlined title="Approve" style={innerTableActionBtnDesign} />
          </Button>
        </Tooltip>

        <Tooltip title="Delete">
          <Button onClick={() => deletePointsRequest(props?.record)} type="secondary">
            <DeleteOutlined title="Delete" style={innerTableActionBtnDesign} />
          </Button>
        </Tooltip>
      </div>
    );
  };

  const backEditPlan = () => setActions({ editPlan: false });

  return (
    <>
      {editPlan ? (
        <Modal
          visible={editPlan}
          onCancel={backEditPlan}
          footer={null}
          destroyOnClose={true}
          width={1000}
        >
          <EditEntry back={backEditPlan} requestsCaller={requestsCaller} data={editValue} />
        </Modal>
      ) : (
        <div className="overflow-x-hidden">
          <ActionButtons
            pageTitle={"Points Manager"}
            showTrashButton={false}
            showTrashFunction={""}
            showReFreshButton={true}
            refreshFunction={requestsCaller}
            showExportDataButton={false}
            exportDataFunction={""}
            totalItems={""}
            csvName={""}
            loadingItems={""}
            downloadItems={""}
            showAddNewButton={false}
            addNewFunction={""}
          />
          <div className="border-2 table-container overflow-x-hidden mt-5">
            <DataTable usersData={pointsApproval} columns={columns} loading={loading} />
          </div>
        </div>
      )}
    </>
  );
};