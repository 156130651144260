import React, { useEffect, useReducer, useState } from "react";
import ActionButtons from "./components/actionsButtons/Index";
import { DataTable } from "./components/table/Index";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  EyeOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { innerTableActionBtnDesign } from "./components/styles/innerTableActions";
import { DrawerComp } from "./components/PointsManager/Drawer";
import { EditEntry } from "./components/PointsManager/EditEntry";
import { Button, Tooltip, Modal } from "antd";
import axios from "../appConfig/httpHelper";

export const PointsManager = () => {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("jwt"));

  const [actions, setActions] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      drawer: false,
      loading: false,
      editPlan: false,
      pagination: 15,
      trash: false,
    }
  );

  const { drawer, loading, editPlan, pagination, trash } = actions;

  const [value, setValue] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    { pointsApproval: [], drawerValue: {}, editValue: {} }
  );

  const { pointsApproval, drawerValue, editValue } = value;

  const [isModalVisible, setIsModalVisible] = useState(false);

  const dummyData = [
    {
      user: { name: "John Doe" },
      toal: 100,
      date: "2024-05-01",
      month: "May",
      monthlyPlanId: "MP001",
      year: 2024,
    },
    // Additional dummy data entries...
  ];

  useEffect(() => {
    requestsCaller();
  }, []);

  const approvePoints = (record) => {
    console.log(record)
    axios
      .post(
        `/monthly-plan/consumption/approve/${record.monthlyPlanId}`,
        {
          points: record?.toal,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const deletePointsRequest = (record) => {
    console.log(record?.id);
    axios
      .delete(`/monthly-plan/consumption/delete/plan-id/${record.monthlyPlanId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        toast.error(err.response.data.error);
      });
  };

  const requestsCaller = () => {
    setActions({ loading: true });
    axios
      .get("/monthly-plan/consumption/get-all", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setValue({
          pointsApproval: res.data.data.filter(val => !val.isApproved)
        });
      })
      .catch((err) => console.log(err))
      .finally(setActions({ loading: false }));
  };

  const onCloseDrawer = () => setActions({ drawer: false });

  const backEditPlan = () => {
    setActions({ editPlan: false });
  };

  const handleEdit = (record) => {
    setValue({ editValue: record });
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      key: "userName",
      title: "User Name",
      render: (data) => data?.user?.name,
    },
    {
      key: "totalPlan",
      title: "Total Plan",
      render: (data) => data.toal,
    },
    {
      key: "date",
      title: "Date",
      render: (data) => data.date,
    },
    {
      key: "month",
      title: "Month",
      render: (data) => data.month,
    },
    {
      key: "uid",
      title: "Month Plan ID",
      render: (data) => data.monthlyPlanId,
    },
    {
      key: "year",
      title: "Year",
      render: (data) => data.year,
    },
    {
      key: "actions",
      title: "Actions",
      width:300,
      render: (record) => (
        <div className="flex justify-around">
          <Button type="secondary">
            <EyeOutlined
              title="View"
              style={innerTableActionBtnDesign}
              onClick={() => {
                setActions({ drawer: true });
                setValue({ drawerValue: record });
              }}
            />
          </Button>
          <Button
            type="secondary"
            onClick={() => handleEdit(record)}
          >
            <EditOutlined
              title="Edit"
              style={innerTableActionBtnDesign}
            />
          </Button>
          <Button
            type="secondary"
            onClick={() => approvePoints(record)}
          >
            <Tooltip title="Approve">
              <CheckOutlined style={innerTableActionBtnDesign} />
            </Tooltip>
          </Button>
          <Button
            type="secondary"
            onClick={() => deletePointsRequest(record)}
          >
            <DeleteOutlined
              title="Delete"
              style={innerTableActionBtnDesign}
            />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="">
        <ActionButtons
          pageTitle={"Points Manager"}
          showTrashButton={false}
          showTrashFunction={""}
          showReFreshButton={true}
          refreshFunction={requestsCaller}
          showExportDataButton={false}
          exportDataFunction={""}
          totalItems={""}
          csvName={""}
          loadingItems={""}
          downloadItems={""}
          showAddNewButton={false}
          addNewFunction={""}
        />
        <div className="border-2 overflow-x-hidden mt-5 table-container">
          <DataTable
            usersData={pointsApproval}
            columns={columns}
            loading={loading}
          />
        </div>
        <div className="">
          <DrawerComp
            title={"Product Details"}
            visible={drawer}
            onCloseDrawer={onCloseDrawer}
            data={drawerValue}
          />
        </div>
      </div>
      <Modal
        title="Edit Entry"
        visible={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={1100}
      >
        <EditEntry
          back={handleModalCancel}
          requestsCaller={requestsCaller}
          data={editValue}
        />
      </Modal>
    </>
  );
};