import React, { useEffect, useState } from "react";
import { Layout, Menu, Dropdown, Modal,Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined, DownOutlined, BellOutlined } from "@ant-design/icons";
import { logout } from "../../../store/actions/user";
import axios from "../../../appConfig/httpHelper";
import 'antd/dist/antd.css';

export const HeaderElement = ({ title }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);



  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(true);
  // const [notificationData, setNotificationData] = useState([]);

  const { Header } = Layout;


  const notificationData=[
    { text: "New message received", date: "2024-05-01" },
    { text: "New message received", date: "2024-05-01" },
    { text: "New message received", date: "2024-05-01" },
    { text: "New message received", date: "2024-05-01" },
    { text: "New message received", date: "2024-05-01" },

    { text: "Payment processed successfully", date: "2024-05-02" },
    { text: "Upcoming event reminder", date: "2024-05-03" },
    { text: "Account balance updated", date: "2024-05-04" },
    { text: "New product added to wishlist", date: "2024-05-05" }
  ]
  const DropDownMenu = () => (
    <Menu style={{ borderRadius: '20px', backgroundColor: 'white', padding: 'px' }}>
    <Menu.Item key="1" style={{ borderRadius: '20px', margin: '5px' }}>
      User Profile
    </Menu.Item>
    <Menu.Item key="2" style={{ borderRadius: '20px', margin: '5px' }} onClick={() => dispatch(logout())}>
      LogOut
    </Menu.Item>
  </Menu>
  );

  const getNotifications = () => {
    const token = JSON.parse(localStorage.getItem("jwt"));
    axios
      .get(`/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        // setNotificationData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => getNotifications(), []);


  

  const Notification = () => (
    <Modal
    className="custom-modal"
    title="Notifications"
    style={{ top: 80, right: 60, position: "absolute", borderRadius: '20px' }}
    visible={showNotification}
    closable={true}
    cancelButtonProps={{ style: { display: "none" } }}
    okButtonProps={{ style: { display: "none" } }}
    onCancel={() => {
      setShowNotification(false);
      setNotification(false);
    }}
    bodyStyle={{ maxHeight: '400px', overflowY: 'auto' }} // Adjust the maxHeight as needed
  >
    <button className="text-black underline hover:no-underline">
      Mark All Read
    </button>
    <div style={{ height: '300px', overflowY: 'auto' }}> {/* Set a fixed height for the content */}
      {notificationData.map((data, index) => (
        <div key={index} className="flex items-start bg-white shadow-md p-4 rounded-lg mb-1 border border-gray-300 hover:bg-gray-100 transition duration-200">
          <div className="flex-1">
            <h1 className="m-0 text-lg font-semibold text-gray-800">{data?.text}</h1>
            <h2 className="text-right text-sm text-gray-500 mt-2">{`${data?.date}`}</h2>
          </div>
        </div>
      ))}
    </div>
  </Modal>
  );

  console.log(notification, "Hello");

  return (
    <Header
      style={{
        paddingLeft: "35px",
        backgroundColor: "#ffff",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1,
        boxShadow: "0px 1px 8px #c1c1c1",
      }}
    >
      <h1 className="text-purple-1 text-xl m-0">Admin Dashboard / {title}</h1>
      <div className="flex items-center justify-center">
        <button
          onClick={() => setShowNotification(true)}
          className={`mr-8 w-11 h-11 p-2 rounded-full flex items-center justify-center  ${
            notification
              ? `bg-purple-1 bg-opacity-10 border-1 border-purple-1 animate-pulse`
              : ""
          }`}
        >
          {notification ? (
            <span class="flex h-3 w-3 absolute -mt-8 -mr-8">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-black opacity-75"></span>
              <span class="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
            </span>
          ) : null}
          <BellOutlined style={{ color: "#140035", fontSize: 24 }} />
        </button>
        <Notification />
        <Dropdown overlay={DropDownMenu} className="cursor-pointer">
          <div className="flex items-center">
            <h1 className="text-purple-1 text-base pr-3 m-0">{user.name}</h1>

            <div className="">
              <UserOutlined
                style={{
                  backgroundColor: "#fff",
                  fontSize: 28,
                  borderRadius: 14,
                }}
              />
              <DownOutlined style={{ color: "#140035", paddingLeft: 8 }} />
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
};
