import React, {useEffect} from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Importing Components
import { Login } from "./login";
import { Dashboard } from "./dashboard/dashboard";
import { DashboardStats } from "./dashboard";
import { Users } from "./users/Index";
import { Approvals } from "./approvals/approvals";
import { PointsManager } from "./pointsManager";
import { Assets } from "./assetMaster";
import {
  getIndustryType,
  getPackagingType,
  getSourceType,
  getUom,
} from "../store/actions/statics";
import { ActualConsumptionManager } from "./actualConsumptionManger";
import { StaticsManager } from "./staticsManager/Index";
import { DefaultedPoints } from "./defaultedPoints";
import { ProductMaster } from "./products/products";
import UserLogs from "./UserLogs";




export const Navigation = () => {

  const dispatch = useDispatch()

  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.user.auth);
  const token = JSON.parse(localStorage.getItem("jwt"));

  const navigate = useNavigate();
  const handleUnAuth = () => navigate("/login");

  
  useEffect(() => {
    dispatch(getIndustryType());
    dispatch(getSourceType());
    dispatch(getUom());
    dispatch(getPackagingType());
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {user.role === 3 && token && auth ? (
        <>
          <Route path="/admin" element={<Dashboard />}>
            <Route path="dashboard" element={<DashboardStats />} />
            <Route path="users" element={<Users />} />
            <Route path="approvals" element={<Approvals />} />
            <Route path="pointsmanager" element={<PointsManager />} />
            <Route path="actualconsumptionmanager" element={<ActualConsumptionManager />} />
            <Route path="staticsmanager" element={<StaticsManager />} />
            <Route path="productmaster" element={<ProductMaster />} />
            {/* <Route path="defaultedpoint" element={<DefaultedPoints/>}/> */}
            <Route path="assetmaster" element={<Assets />} />
            <Route path="userlog" element={<UserLogs />} />
          </Route>
        </>
      ) : (
        () => handleUnAuth()
      )}
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
