import React, { useEffect, useState } from 'react';
import axios from "../appConfig/httpHelper";
import ActionButtons from "./components/actionsButtons/Index";
import { Table } from 'antd';

const columns = [
  {
    key: 'userId',
    title: 'UserID',
    dataIndex: 'userId'
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name'
  },
  {
    key: 'role',
    title: 'Role',
    dataIndex: 'role'
  },
  {
    key: 'timestamp',
    title: 'Timestamp',
    render: (data) => <div>{new Date(data?.timestamp).toLocaleString('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: true // Use false for 24-hour time
}).toString()}</div>,
  },
  {
    key: 'operation',
    title: 'Operation',
    render: (data) => <div>{data?.operation}</div>,
  }
];

const UserLogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("jwt"));

  useEffect(() => {
    setLoading(true);
    axios.get('/logsdata/get-all?limit=10000', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        const reversedData = response.data.data.reverse(); // Reverse the data array
        setData(reversedData); // Setting the reversed data to the state
        console.log("userlogdata", reversedData);
        setLoading(false);
      })
      .catch(error => {
        console.error("There was an error fetching the logs!", error);
        setLoading(false);
      });
  }, [token]);

  return (
    <div className=''>
      <ActionButtons pageTitle={"User Events"} />
      <div className="border-2 mt-5 shadow-2xl table-container">
        <Table
          columns={columns}
          dataSource={data}
          rowKey="id"
          loading={loading}
          className='font-montserrat bg-[#dbdbdb] font-semibold'
        />
      </div>
    </div>
  );
}

export default UserLogs;