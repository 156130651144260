import React, { useEffect, useReducer, useState } from "react";
import { DataTable } from "../components/table/Index";
import ActionButtons from "../components/actionsButtons/Index";
import axios from "../../appConfig/httpHelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Drawer } from "antd";
import { DrawerComp } from "../components/products/Drawer";
import { FilterDrawer } from "../components/products/FilterDrawer";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { innerTableActionBtnDesign } from "../components/styles/innerTableActions";
import { EditProduct } from "../components/products/EditProduct";
import { useSelector } from "react-redux";

export const ProductMaster = () => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const user = useSelector((state) => state.user);
  const [showEdit, setShowEdit] = useState(false);

  // Declaring the States Required for the Working of the Component
  const [actions, setActions] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      drawer: false,
      loading: false,
      filter: false,
      pagination: 15,
      trash: false,
      newProduct: false,
      loadingAllProducts: false,
      downloadAllProducts: false,
    }
  );

  const {
    drawer,
    loading,
    filter,
    pagination,
    trash,
    newProduct,
    loadingAllProducts,
    downloadAllProducts,
  } = actions;

  const [value, setValue] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      products: [],
      allProducts: [],
      drawerValue: {},
      filterValue: {},
      editValue: {},
    }
  );

  const { products, allProducts, drawerValue, filterValue, editValue } = value;

  // Functions Used for Different Data
  const requestsCaller = () => {
    setActions({ loading: true });
    setTimeout(() => {
      const dummyProducts = [
        {
          title: "Product 1",
          user: { name: "User 1" },
          productCode: "P001",
          packagingType: ["Box", "Carton"],
          uom: "kg",
          description: " Product 1",
          points: 10,
          isApproved: true,
          productId: 1,
        },
        {
          title: "Product 2",
          user: { name: "User 2" },
          productCode: "P002",
          packagingType: ["Bag"],
          uom: "ltr",
          description: " Product 2",
          points: 20,
          isApproved: false,
          productId: 2,
        },
        {
          title: "Product 3",
          user: { name: "User 3" },
          productCode: "P003",
          packagingType: ["Box"],
          uom: "pcs",
          description: "Description for Product 3",
          points: 30,
          isApproved: true,
          productId: 3,
        },
      ];
      setValue({ products: dummyProducts });
      setActions({ loading: false });
    }, 1000); // Simulating a network request delay of 1 second
  };

  const getAllProducts = () => {
    setActions({ loadingAllProducts: true });
    setTimeout(() => {
      const dummyAllProducts = [
        {
          title: "Product 4",
          user: { name: "User 4" },
          productCode: "P004",
          packagingType: ["Bottle"],
          uom: "g",
          description: " Product 4",
          points: 40,
          isApproved: true,
          productId: 4,
        },
        {
          title: "Product 5",
          user: { name: "User 5" },
          productCode: "P005",
          packagingType: ["Jar"],
          uom: "ml",
          description: " Product 5",
          points: 50,
          isApproved: false,
          productId: 5,
        },
      ];
      toast.success("Products Ready for Download");
      setActions({ downloadAllProducts: true });
      setValue({ allProducts: dummyAllProducts });
      setActions({ loadingAllProducts: false });
    }, 1000);
  };

  const getProductBySearch = (value) => {
    setActions({ loading: true });
    // Dummy implementation for search functionality
    setTimeout(() => {
      const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(value.toLowerCase())
      );
      toast.success("Search Performed Successfully.");
      setValue({ products: filteredProducts });
      setActions({ loading: false });
    }, 500);
  };

  const getFilteredProduct = (value) => {
    setActions({ loading: true });
    const filterValue = {};
    filterValue.userId = user.id;
    if (value.packagingTypeSelected !== "") {
      filterValue.packagingType = value.packagingTypeSelected;
    }
    if (value.uomSelected !== "") {
      filterValue.uom = value.uomSelected;
    }
    // Dummy implementation for filter functionality
    setTimeout(() => {
      const filteredProducts = products.filter(
        (product) =>
          (filterValue.packagingType
            ? product.packagingType.includes(filterValue.packagingType)
            : true) &&
          (filterValue.uom ? product.uom === filterValue.uom : true)
      );
      toast.success("Filters Applied Successfully.");
      setValue({ products: filteredProducts });
      setActions({ loading: false });
    }, 500);
  };

  const DeleteItem = (productId) => {
    setValue({
      products: products.filter((product) => product.productId !== productId),
    });
    toast.success("Product Deleted Successfully");
  };

  useEffect(() => requestsCaller(), []);

  // This Columns Variable is used to determine The Values getting inside the Table Component
  const columns = [
    {
      key: "title",
      title: "Title",
      render: (data) => data.title,
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      key: "userName",
      title: "User Name",
      render: (data) => data.user?.name,
      sorter: (a, b) => a.title.length - b.title.length,
    },
    // {
    //   key: "productCode",
    //   title: "Product Code",
    //   render: (data) => data.productCode,
    // },
    {
      key: "packagingType",
      title: "Packaging Type",
      render: (data) =>
        data.packagingType.map((type, index) => (
          <div key={index}>
            <p>{type}</p>
          </div>
        )),
    },
    {
      key: "uom",
      title: "UOM",
      render: (data) => data.uom,
    },
    {
      key: "description",
      title: "Description",
      
      render: (data) => data.description,
    },
    {
      key: "points",
      title: "Points",
      render: (data) => data.points,
      sorter: (a, b) => a.points - b.points,
    },
    {
      key: "isApproved",
      title: "Status",
      render: (data) => (data.isApproved ? "Approved" : "Pending"),
    },
    {
      key: "actions",
      title: "Actions",
      width:300,

      render: (record) => <ColumnActions record={record} />,
    },
  ];

  const ColumnActions = (props) => {
    return (
      <div className="flex justify-around">
        <Button type="secondary">
          <EyeOutlined
            title="View"
            style={innerTableActionBtnDesign}
            onClick={() => {
              setActions({ drawer: true });
              setValue({ drawerValue: props?.record });
            }}
          />
        </Button>
        <Button type="secondary" className="pl-2">
          <EditOutlined

            title="Edit"
            style={innerTableActionBtnDesign}
            onClick={() => {
              setShowEdit(true);
              setValue({ editValue: props?.record });
              console.log(props?.record, "Hello");
            }}
          />
        </Button>
        {!props?.record?.isApproved ? (
          <div className="pr-1 pl-1">
             <Button type="secondary">
            <DeleteOutlined
              title="Ban"
              style={innerTableActionBtnDesign}
              onClick={() => DeleteItem(props?.record?.productId)}
            />
          </Button>
          </div>
         
        ) : null}
      </div>
    );
  };

  const backEditNewProduct = () => setShowEdit(false);

  const onCloseDrawer = () => {
    setActions({ drawer: false });
    setValue({ drawerValue: {} });
  };

  const openFilterDrawer = () => {
    setActions({ filter: true });
  };

  const onCloseFilterDrawer = () => {
    setActions({ filter: false });
    setValue({ filterValue: {} });
  };

  return (
    <>
 
      
      
        <div className="">
          <ActionButtons
            pageTitle={"Products"}
            showSearchButton={true}
            onSearch={getProductBySearch}
            showFilterButton={true}
            onFilter={openFilterDrawer}
            showTrashButton={false}
            showTrashFunction={""}
            showReFreshButton={true}
            refreshFunction={requestsCaller}
            showExportDataButton={true}
            exportDataFunction={getAllProducts}
            totalItems={allProducts}
            csvName={"Products.csv"}
            loadingItems={loadingAllProducts}
            downloadItems={downloadAllProducts}
            showAddNewButton={false}
            addNewFunction={""}
          />
          <div className="border-2 mt-5 table-container">
            <DataTable usersData={products}
             columns={columns} loading={loading} />
          </div>
          <div>
            <DrawerComp
              title={"Product Details"}
              visible={drawer}
              onCloseDrawer={onCloseDrawer}
              data={drawerValue}
            />
          </div>
          <div>
            <FilterDrawer
              title={"Set Product Filters"}
              visible={filter}
              onCloseDrawer={onCloseFilterDrawer}
              data={filterValue}
              applyFilter={getFilteredProduct}
              resetFilter={requestsCaller}
            />
          </div>
        </div>
        <Drawer
        placement="right"
        width={400}
        visible={showEdit}
        onClose={backEditNewProduct}

        >
     <EditProduct back={backEditNewProduct} data={editValue} />
     </Drawer>
    </>
  );
};
