export const innerTableActionBtnDesign = {
  fontSize: 18,
  fontWeight: "bold",



  
  color: "#4B5563", // Dark gray color

  padding: "0 0",

  transition: "color 0.3s ease-in-out", // Smooth transition effect
  borderRadius: 6, // Rounded corners
  backgroundColor: "transparent", // Transparent background
  border: "none", // No border
  cursor: "pointer", // Pointer cursor on hover
  outline: "none", // Remove outline when focused
  hover: {
    color: "#6B7280", // Darker gray color on hover
  },
  focus: {
    color: "#4B5563",
    boxShadow: "0 0 0 3px rgba(66, 153, 225, 0.5)", // Blue shadow when focused
  },
};