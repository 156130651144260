import React from "react";
import { Table } from "antd";
import "./styles/tableStyles.css";

export const DataTable = ({
  columns,
  usersData = [],
  loading,
  differUserRows,
  rowSelection,
  differRows,
  pagination,
}) => {
  return (
    <div className=" ">
      <Table
        columns={columns}
        dataSource={usersData}
        pagination={true}
      bordered
     
        className=" font-semibold shadow-lg bg-[#dbdbdb]"
        loading={loading}
      
      />
    </div>
  );
};
