import React, { useEffect, useReducer, useState } from "react";
import { DataTable } from "./components/table/Index";
import ActionButtons from "./components/actionsButtons/Index";
import axios from "../appConfig/httpHelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DrawerComp } from "./components/assetMaster/Drawer";
import { Button, Drawer, Tooltip } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { innerTableActionBtnDesign } from "./components/styles/innerTableActions";
import { AddNewAsset } from "./components/assetMaster/AddNewAsset";

export const Assets = () => {
  const token = JSON.parse(localStorage.getItem("jwt"));
  const [showAdd, setShowAdd] = useState(false);

  // Declaring the States Required for the Working of the Component
  const [actions, setActions] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    {
      drawer: false,
      loading: false,
      filter: false,
      pagination: 15,
      trash: false,
      newAsset: false,
      loadingAllAssets: false,
      downloadAllAssets: false,
    }
  );

  const {
    drawer,
    loading,
    filter,
    pagination,
    trash,
    newAsset,
    loadingAllAssets,
    downloadAllAssets,
  } = actions;

  const [value, setValue] = useReducer(
    (state, diff) => ({ ...state, ...diff }),
    { assets: [], allAssets: [], drawerValue: {}, filterValue: {} }
  );

  const { assets, allAssets, drawerValue, filterValue } = value;

  // Functions Used for Different Data
  const requestsCaller = () => {
    setActions({ loading: true });
    axios
      .get("/asset/get-all?limit=500", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setValue({ assets: res.data.data });
      })
      .catch((err) => console.log(err))
      .finally(() => setActions({ loading: false }));
  };

  const getAllAssets = () => {
    setActions({ loadingAllAssets: true });
    axios
      .get("/asset/get-all?limit=500", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        toast.success("Assets Ready for Download");
        setActions({ downloadAllAssets: true });
        setValue({ allAssets: res.data.data });
      })
      .catch((err) => console.log(err))
      .finally(() => setActions({ loadingAllAssets: false }));
  };

  const DeleteItem = (assetId) => {
    axios
      .delete(`/asset/delete/${assetId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        toast.success(res.data.message);
        requestsCaller();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Asset Deletion Failed");
      });
  };

  useEffect(() => {
    requestsCaller();
  }, []);

  // This Columns Variable is used to determine The Values getting inside the Table Component
  const columns = [
    {
      key: "name",
      title: "Name",
      render: (data) => data.name,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      key: "id",
      title: "ID",
      render: (data) => data.id,
    },
    {
      key: "modal",
      title: "Modal",
      render: (data) => data.model,
    },
    {
      key: "industryName",
      title: "Industry Name",
      render: (data) => data.industryName,
    },
    {
      key: "sourceType",
      title: "Source Type",
      render: (data) => data.sourceType,
    },
    {
      key: "make",
      title: "Make",
      render: (data) => data.make,
    },
    {
      key: "model",
      title: "Model",
      render: (data) => data.model,
    },
    {
      key: "serviceNo",
      title: "Service No",
      render: (data) => data.serviceNo,
      sorter: (a, b) => a.serviceNo - b.serviceNo,
    },
    {
      key: "actions",
      title: "Actions",
      render: (record) => <ColumnActions record={record} />,
    },
  ];

  const ColumnActions = (props) => {
    return (
      <div className="flex justify-around">
        <Tooltip title="View">
        <Button
         onClick={() => {
          setActions({ drawer: true });
          setValue({ drawerValue: props?.record });
        }}
        type="secondary">
        <EyeOutlined
          title="View"
          style={innerTableActionBtnDesign}
         
        />
        </Button>
        </Tooltip>
       
     
        {!props?.record?.isApproved ? (
          <Button 
          onClick={() => DeleteItem(props?.record?.assetId)}
          type="secondary">
               <DeleteOutlined
            title="Ban"
            style={innerTableActionBtnDesign}
          
          />
          </Button>
       
        ) : null}
      </div>
    );
  };

  const addNewAsset = () => setShowAdd(true);

  const closeAddNewAsset = () => setShowAdd(false);

  const onCloseDrawer = () => {
    setActions({ drawer: false });
    setValue({ drawerValue: {} });
  };

  return (
    <>
      <ActionButtons
        pageTitle={"Asset Master"}
        showSearchButton={false}
        onSearch={""}
        showFilterButton={false}
        onFilter={""}
        showTrashButton={false}
        showTrashFunction={""}
        showReFreshButton={true}
        refreshFunction={requestsCaller}
        showExportDataButton={true}
        exportDataFunction={getAllAssets}
        totalItems={allAssets}
        csvName={"Assets.csv"}
        loadingItems={loadingAllAssets}
        downloadItems={downloadAllAssets}
        showAddNewButton={true}
        addNewFunction={addNewAsset}
      />
      <div className="border-2 mt-5">
        <DataTable usersData={assets} columns={columns} loading={loading} />
      </div>
      <DrawerComp
        title={"Asset Details"}
        visible={drawer}
        onCloseDrawer={onCloseDrawer}
        data={drawerValue}
      />
      <Drawer
      
        placement="right"
        width={500}
        onClose={closeAddNewAsset}
        visible={showAdd}
      >
        <AddNewAsset back={closeAddNewAsset} requestsCaller={requestsCaller} />
      </Drawer>
    </>
  );
};